import { Injectable } from '@angular/core';
import { Router, Route, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { ALNIdentityAuthService } from 'app/core/auth/aln-identity-auth.service';

@Injectable()
export class ALNIdentityAuthGuard  {
    constructor(private router: Router, 
        private oidcSecurityService: OidcSecurityService, private authService: ALNIdentityAuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkUser();
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
      }

    canLoad(state: Route): Observable<boolean> {
        return this.checkUser();
    }

    private checkUser(): Observable<boolean> {
        return this.oidcSecurityService.checkAuth().pipe(
            map((loginResponse: LoginResponse) => {
                const isAuthenticated = loginResponse?.isAuthenticated;
                if (!isAuthenticated) {
                    this.router.navigate(['/']);
                    return false;
                }
                return true;
            })
        );
    }
}