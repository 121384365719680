<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

<!-- VERTICAL LAYOUT 2 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-2'">
    <vertical-layout-2></vertical-layout-2>
</ng-container>
<!-- / VERTICAL LAYOUT 2 -->

<!-- VERTICAL LAYOUT 3 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-3'">
    <vertical-layout-3></vertical-layout-3>
</ng-container>
<!-- / VERTICAL LAYOUT 3 -->

<!-- HORIZONTAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'horizontal-layout-1'">
    <horizontal-layout-1></horizontal-layout-1>
</ng-container>
<!-- / HORIZONTAL LAYOUT 1 -->

<!-- THEME OPTIONS PANEL -->
<!-- FUSE_TEMP_HIDE
<button mat-icon-button class="warn mat-elevation-z2 theme-options-button"
        (click)="toggleSidebarOpen('themeOptionsPanel')">
    <mat-icon>settings</mat-icon>
</button>
-->


<fuse-sidebar name="themeOptionsPanel" class="theme-options-sidebar" position="right" [invisibleOverlay]="true">
    <fuse-theme-options></fuse-theme-options>
</fuse-sidebar>
<!-- / THEME OPTIONS PANEL -->
<div id="myModal" class="modal-Idel" *ngIf="Show">
    <div class="modal-content-Idel">
        <div class="modal-header-Idel">
            <h2>{{title}}</h2>
        </div>
        <div class="modal-body-Idel">
            <div fxLayout="row">
                <mat-icon class="mat-icon-css" matSuffix>access_time</mat-icon>
                <p>Your online session will expire in</p>
            </div>
            <p class="timer-css">{{idleState}} Secs</p>
            <p>Please click on "Continue" to keep working<br/>or click "Log Off" to end your session now.</p>

            <div class="modal-footer-Idel">
                <button type="button" (click)="stay()" mat-button class="btn-Confirm-Idel">Continue</button>
                <button type="button" (click)="logout()" mat-button class="btn-Cancel-Idel">Log Off</button>
            </div>
        </div>
    </div>
</div>

