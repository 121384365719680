import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    let matProgressBar = document.querySelector("mat-progress-bar");
    if(matProgressBar)
    {
      matProgressBar.classList.remove('mat-progress-bar');
    }
    
  }

}
