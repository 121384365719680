import { User } from 'app/core/models/user';
import { ClientDto } from 'app/core/models/clientDto';

export class ALNIdentityAuthUser {
    preferred_username: string;
    email: string;
    userId: string;
    name: string;
    organizationName: string;
    organizationGUID:string;
    profilePictureUrl: string;
    userRoles: string | string[];
    products: string | string[];
    allProducts: ClientDto[]
    sub: string;
    exp: number;
    constructor(value: any) {
        Object.assign(this, value);
    }
}

//todo: move to own file
export class OidcIdToken {
    exp: number;
}