import { Component, ViewEncapsulation, AfterViewInit } from '@angular/core';

//please see index.html for requirement
declare var eDocketsAngularAppLoaded: boolean;

@Component({
    selector     : 'content',
    templateUrl  : './content.component.html',
    styleUrls    : ['./content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContentComponent implements AfterViewInit
{
    /**
     * Constructor
     */
    constructor()
    {
        
    }
    ngAfterViewInit(): void {
        eDocketsAngularAppLoaded = true;

    }
}
