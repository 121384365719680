import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';

const BASE_URL_ADMIN_API = environment.adminApiUrl;
const BASE_URL_CUSTOMMESSAGES_API = environment.cutomMessagesApiUrl;

@Injectable({
  providedIn: 'root'
})
export class WebApiService {
 private options = { headers: new HttpHeaders().set( 'Content-Type', 'application/json'), withCredentials: false };
  
  private ADMIN_API:string;
  private CUSTOM_MESSAGES_API:string;

  constructor(private httpClient: HttpClient) {
    this.ADMIN_API=BASE_URL_ADMIN_API;
    this.CUSTOM_MESSAGES_API=BASE_URL_CUSTOMMESSAGES_API;
  }

  public getAdmin(path: string): Observable<any> {
    return this.httpClient.get(this.ADMIN_API + path).pipe(catchError(this.formatErrors));
  }
  public getCustomMessages(path: string): Observable<any> {
    return this.httpClient.get(this.CUSTOM_MESSAGES_API + path).pipe(catchError(this.formatErrors));
  }

  public postAdminWrite(path: string, body: object = {}): Observable<any> {
    return this.httpClient
      .post(this.ADMIN_API + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }

  public updateAdmin(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.patch(this.ADMIN_API + path, { params }).pipe(catchError(this.formatErrors));
  }

  public updateAdminWithBody(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.patch(this.ADMIN_API + path, body, { params }).pipe(catchError(this.formatErrors));
  }

  public updateCustomMessageWithBody(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.patch(this.CUSTOM_MESSAGES_API + path, body, { params }).pipe(catchError(this.formatErrors));
  }

  public formatErrors(error: any): Observable<any> {
    return throwError(error.error);
  }

}
