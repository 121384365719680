<!--
    *ngIf="true" hack is required here for router-outlet to work
    correctly. Otherwise, it won't register the changes on the
    layout and won't update the view.
 -->
 <div id="app-temp"></div>
 <div id="case-management"></div>
 <div id="app-autodocket"></div>
 <div id="app-dashboard"></div>
 <div id="app-app1"></div>
 <div id="admin-management"></div>
 <div id="app-docketalert"></div>
 <div id="cr-management"></div>