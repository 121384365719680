import { EventEmitter, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions} from '@microsoft/signalr'
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack'
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CustomMessageDto } from 'app/core/models/custom-message-dto';
import { environment } from 'environments/environment';
import { camelCase } from 'lodash';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  private signalRApiUrl = environment.signalRApiUrl;
  private hubConnection: HubConnection
  messagesListChange: EventEmitter<CustomMessageDto[]> = new EventEmitter();

  constructor(public oidcSecurityService: OidcSecurityService) { }

  public connect = () => {
    this.startConnection();
    this.addListeners();
  }
  public getToken(): Observable<string> {
    return this.oidcSecurityService.getAccessToken();
  }

  private startConnection() {

    this.getToken()
    .subscribe((accessToken: string) => {
      
        if(accessToken){

          const options: IHttpConnectionOptions = {
            accessTokenFactory: () => { return accessToken; },
            withCredentials: false,
            transport: signalR.HttpTransportType.LongPolling
          };      
          this.hubConnection = new HubConnectionBuilder()
                .withUrl(this.signalRApiUrl, options)            
                .withHubProtocol(new MessagePackHubProtocol())
                .withAutomaticReconnect()  
                .configureLogging(signalR.LogLevel.None)    
                .build();

          this.hubConnection.start()
            .then(() => console.log('connection started'))
            .catch((err) => console.log('error while establishing signalr connection: ' + err));
        }
    });
  }

  private addListeners() {
    this.hubConnection.on("ReceiveCustomMessages", (data: CustomMessageDto[]) => {
      this.emitMessagesListChangeEvent(this.camelizeKeys(data));
    });
    this.hubConnection.on("BroadcastGlobalCustomMessage", () => {
      this.emitMessagesListChangeEvent(null);
    });
  }

  emitMessagesListChangeEvent(messages: CustomMessageDto[]) {
    this.messagesListChange.emit(messages);
  }

  getMessagesListEvent() {
    return this.messagesListChange;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(v => this.camelizeKeys(v));
    } else if (obj != null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {},
      );
    }
    return obj;
  };
}