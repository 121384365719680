import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpSentEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CacheControlInterceptor implements HttpInterceptor {

  private regexp: RegExp = new RegExp('assets/[A-Za-z]+Content[0-9_]*[.][a-z]{3,4}');

  constructor() { }
    
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    //temp change, this will be moved to API. We also need to figure out if we really need this interceptor, there are better ways to handle this
    // if(request?.url && this.regexp.test(request.url))
    // {
    //   return next.handle(request);
    // }

    // no-cache related headers:
    // 1. Cache-Control         For HTTP 1.1. servers
    // 2. Pragma                Compatible with legacy HTTP 1.0 servers that do not support Cache-Control header
    // 3. Expires               Sets the expiry date as the unix epoch
    // 4. If-Modified-Since     Explicitly state that the request must comply with the given range
    const noCacheRequest = request.clone({
      headers: request.headers.set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache')
        .set('Expires', 'Thu, 01 Jan 1970 00:00:00 GMT')
        .set('If-Modified-Since', 'Thu, 01 Jan 1970 00:00:00 GMT')
    });

    // Make sure request is the one we care about i.e. the connect/token one
    if (noCacheRequest.method === 'POST' && noCacheRequest.url.indexOf("/connect/token") !== -1) {
      //Handle error for this particular request by redirecting to home page since we know that it works
      return next.handle(noCacheRequest)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            
            let appURL=`${env.clientUrl}${env.postLoginUrl}`;
              if (window.location.toString() !== appURL) {
                  window.location.href = `${appURL}`;
              }
            return throwError(error);
          })
        );
      
    }

    return next.handle(noCacheRequest);
  }

}
