<div id="myModal" class="modal-Idel">
    <div class="modal-content-Idel">
        <div class="modal-header-Idel">
            <h2>{{title}}</h2>
        </div>
        <div class="modal-body-Idel">
            <div fxLayout="row">
                <mat-icon class="mat-icon-css" matSuffix>access_time</mat-icon>
                <p>Your online session will expire in</p>
            </div>
            <p class="timer-css">{{idleState}} Secs</p>
            <p>Please click on "Continue" to keep working<br/>or click "Log Off" to end your session now.</p>

            <div class="modal-footer-Idel">
                <button type="button" (click)="dialogRef.close('stay')" mat-button class="btn-Confirm-Idel">Continue</button>
                <button type="button" (click)="dialogRef.close('logout')" mat-button class="btn-Cancel-Idel">Log Off</button>
            </div>
        </div>
    </div>
</div>