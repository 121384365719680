export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'EDOCKETS': 'eDockets',
            'SAMPLE': {
                'TITLE': 'Sample',
                'BADGE': '25'
            },
            'AUTODOCKET': {
                'TITLE': 'Autodocket',
                'BADGE': '2'
            },
            'CASE-MANAGEMENT': {
                'TITLE': 'Case Management',
                'BADGE': '3'
            },
            'ADMIN-MANAGEMENT': {
                'TITLE': 'Admin Management',
                'BADGE': '1'
            },
            'ATTORNEY-MANAGEMENT': {
                'TITLE': 'Admin Management',
                'BADGE': '3'
            },
            'APP-SETTINGS': {
                'TITLE': 'App Settings',
                'BADGE': '3'
            },
            'STANDARD-PARTY': {
                'TITLE': 'Standard Party',
                'BADGE': '3'
            },
            'JUDGES': {
                'TITLE': 'jUDGES',
                'BADGE': '3'
            },
            'DEFAULT-REMINDERS': {
                'TITLE': 'Default Reminders',
                'BADGE': '3' 
            },
            'OFFICES': {
                'TITLE': 'Offices',
                'BADGE': '3'
            },
            'CASE-MANAGEMENT-HOME.TITLE': {
                'TITLE': 'Case Management Home',
                'BADGE': '3'
            },
            'DASHBOARD': {
                'TITLE': 'DashBoard',
                'BADGE': '2'
            },
            'DOCKETDIRECT': {
                'TITLE': 'Docket Direct',
                'BADGE': '2'
            },
            'TASKS': {
                'TITLE': 'Tasks',
            },
            'REPORTS': {
                'TITLE': 'Reports',
            },
            'EFILING': {
                'TITLE': 'E-FILING',
            },
            'SMARTDOCKETS': {
                'TITLE': 'Smart Dockets',
            },
            'MAIL': {
                'TITLE': 'Mail',
            },
            'CALENDAR': {
                'TITLE': 'eDockets Calendar',
            },
            'ENTERPRISE': {
                'TITLE': 'Enterprise Suite',
            },
            'FORMSWORKFLOW': {
                'TITLE': 'Forms Workflow',
            },
            'DOCKETALERTS': {
                'TITLE': 'Docket Alerts',
                'BADGE': '2'
            },
            'CASESEARCH': {
                'TITLE': 'Case Search',
                'BADGE': '2'
            },
            'MYDOCKETALERT': {
                'TITLE': 'My Docket Alerts',
                'BADGE': '2'
            },
            'MYEDOCSALERT': {
                'TITLE': 'My eDocs Alert',
                'BADGE': '2'
            },
            'ALLMYCASE': {
                'TITLE': 'All My Case',
                'BADGE': '2'
            },
            'MYREPORTS': {
                'TITLE': 'My Reports',
                'BADGE': '2'
            },
            'ORDERSOPINIONS': {
                'TITLE': 'Orders and Opinions',
                'BADGE': '2'
            },
            'PARTYWATCH': {
                'TITLE': 'Party Watch',
                'BADGE': '2'
            },
            'NATURESUITWATCH': {
                'TITLE': 'Nature of Suit Watch',
                'BADGE': '2'
            },
            'QUICKDATES': {
                'TITLE': 'Quick Dates',
            },          
        }
    }
};
