import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainLayoutComponent } from './main-layout/main-layout.component';
import { ALNIdentityAuthGuard } from './guards/aln-identity-auth.guard';
import { AppGatewayComponent } from '../core/appgateway.component';
import { environment as env } from 'environments/environment';

@NgModule({
    imports: [
        RouterModule.forChild([
            { 
                path: '', 
                pathMatch: 'full', 
                redirectTo: env.postLoginUrl, 
                canActivate: [ALNIdentityAuthGuard],
                canActivateChild: [ALNIdentityAuthGuard],
                children: [
                { path: 'appgateway/:landingpage', component: AppGatewayComponent }
            ]},
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class MainRoutingModule { }