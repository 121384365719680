import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from './models/user';
import { environment } from '../../environments/environment';
import { ALNIdentityAuthService } from 'app/core/auth/aln-identity-auth.service';

@Component({
    selector: 'redirect',
    template: 'redirecting...'
})
export class AppGatewayComponent implements OnInit {

    currentUser: User;
    constructor(
        private route: ActivatedRoute,
        private authenticationService: ALNIdentityAuthService
    ) {
    }

    ngOnInit(): void {
        var cacheKeyGuid;
        this.route.queryParams.subscribe(params => {
            cacheKeyGuid = params['cacheKeyGuid'];
        });
        this.currentUser = this.authenticationService.user;
        if (this.route.snapshot.params['landingpage'] === 'mail') {
            window.location.href = `${environment.centralAppBaseUri}/apps/appgateway/${this.currentUser.userId}/mail`;
        }
        else if (this.route.snapshot.params['landingpage'] === 'calendar') {
            window.location.href = `${environment.centralAppBaseUri}/apps/appgateway/${this.currentUser.userId}/calendar`;
        }
        else if (this.route.snapshot.params['landingpage'] === 'profile') {
            window.location.href = `${environment.myProfileUri}`;
        }
        else if (this.route.snapshot.params['landingpage'] === 'administration') {
            window.location.href = `${environment.administrationUri}`;
        }
        else if (this.route.snapshot.params['landingpage'].startsWith('addevents')) {
            window.location.href = `${environment.centralAppBaseUri}/apps/appgateway/${this.currentUser.userId}/addevents?cacheKeyGuid=${cacheKeyGuid}`;
        }
        else if (this.route.snapshot.params['landingpage'] === 'changepassword') {
            window.location.href = `${environment.changePassword}`;
        }
        else if (this.route.snapshot.params['landingpage'] === 'home') {
            window.location.href = `/event-calculator/summary`;
        }
    }
}

