export class User {
    userId: string;
    userName: string;
    fName: string;
    lName: string;
    fullName: string;
    profileImageURL: string;
    bearerToken: string;
    claims: any[];

    organisation: any;
    applicationRoles: any[];

    clients: any[];
    allClients: any[];

    //Change of parent folder -- lower case models
}
