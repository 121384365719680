import { Injectable } from "@angular/core";
import { LogLevel, OpenIdConfiguration, StsConfigStaticLoader } from "angular-auth-oidc-client";
import { environment as env } from "environments/environment";

@Injectable({ providedIn: 'root' })
export class ConfigService {
    getConfig(): OpenIdConfiguration {
      return {
              authority: env.stsUrl,
              redirectUrl: `${env.clientUrl}${env.redirectRoute}`,
              postLoginRoute: this.redirectToUrl(),
              postLogoutRedirectUri: env.clientUrl,
              clientId: env.clientId,
              scope: 'openid profile email edapi:access:full',
              responseType: 'code',
              silentRenew: true,
              silentRenewUrl: `${env.clientUrl}/${env.silentRenewRoute}`,
              //useRefreshToken: true,
              logLevel: env.logConsoleDebugActive ? LogLevel.Debug : LogLevel.Error,
              disableIatOffsetValidation: true,
              autoUserInfo: false
      };
    }

    redirectToUrl(): string {
        const storageKey: string = "currentPostLoginUrl";
        let indexAppgateway: number = window.location.href.indexOf("appgateway");
        let indexSilentRenew: number = window.location.href.indexOf("silent-renew");
        if(indexAppgateway < 0 && indexSilentRenew < 0) {
            var currentUrlPath = window.location.pathname + window.location.search + window.location.hash;
            sessionStorage.setItem(storageKey, currentUrlPath);
        }

        let url: string = sessionStorage.getItem(storageKey) ? sessionStorage.getItem(storageKey) : env.postLoginUrl;
 
        let adLandingUrlIndex: number = window.location.href.indexOf("autodocket/docket/");
        if (adLandingUrlIndex > 0) {
            sessionStorage.setItem("adLandingPage", JSON.stringify(window.location.pathname));
            url = window.location.pathname;
        }
        const adLandingPagePath = JSON.parse(sessionStorage.getItem("adLandingPage"));
        if (adLandingPagePath && adLandingUrlIndex <= 0) {
            url = JSON.parse(sessionStorage.getItem("adLandingPage"));
            sessionStorage.removeItem('adLandingPage');
        }

        url = this.checkAndReplaceBadUrl(url);
        return url;
    }

    checkAndReplaceBadUrl(url: string): string {
        if(!url || url === "/" || url === "null" || url === "undefined"
            || url.indexOf("silent-renew") > -1 
            || url.indexOf("appgateway") > -1) {
            url = env.postLoginUrl;
        }
        return url;
    }
}
  
export const authFactory = (configService: ConfigService) => {
    const config = configService.getConfig();
    return new StsConfigStaticLoader(config);
};