<div id="noaccess-form" style="background: #fff; width: 384px; max-width: 384px; text-align: center; margin: 0 auto; margin-top: 70px;">
    <div style="width: 100%; padding: 32px;">
        <div style="width: 128px; margin: 32px auto;">
            <img src="assets/images/logos/Logo_V4.png" class="ng-tns-c215-113">
        </div>
        <div style="font-size: 20px; margin-top: 16px; font-weight: bold;">
            Sorry, you have no access to this product
        </div>
        <div style="margin: 16px auto 0; text-align: center; max-width: 300px; font-size: 14px;">
            Please go to user profile to add this product
        </div>
</div>
</div>