import { CustomMessageDto } from "./custom-message-dto";

export class CustomMessagesResDto{
    is_success : boolean;
    message: string;
    content: CustomMessageDto[];
}
export class CheckNewCustomMessagesResDto{
    is_success : boolean;
    message: boolean;
    content: boolean;
}

export class CustomMessagesGuids{
    customMessageGuids : string;
}