import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AppGatewayComponent } from './core/appgateway.component';
import { SampleComponent } from './main/sample/sample.component';
import { ALNIdentityAuthGuard } from './main/guards/aln-identity-auth.guard';
import { environment as env } from '../environments/environment';

const appRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: env.postLoginUrl, canActivate: [ALNIdentityAuthGuard] },
  { path: 'appgateway/:landingpage', component: AppGatewayComponent, pathMatch: 'full' }
];


@NgModule({
  imports: [
      RouterModule.forRoot(appRoutes, {
    onSameUrlNavigation: 'reload',
    preloadingStrategy: PreloadAllModules,
    useHash: false,
    anchorScrolling: 'enabled'
})
    ],
  exports: [RouterModule],
  providers: [
  ]
})
export class
  AppRoutingModule {
}
